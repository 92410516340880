/**
 * 脱敏工具类
 */

/**
 * 手机号脱敏
 */
function phoneHide(phone) {
    const reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/;
    return phone.replace(reg, '$1****$2');
}

/**
 * 邮箱脱敏
 */
function emailHide(email) {
    const [username, domain] = email.split('@');
    const avg = Math.floor(username.length / 2);
    const maskedUsername = username.slice(0, avg) + '***';
    return `${maskedUsername}@${domain}`;
}

/**
 * 身份证号脱敏
 */
function cardHide(card) {
    const reg = /^(.{6})(?:\d+)(.{4})$/;
    return card.replace(reg, '$1******$2');
}

/**
 * 姓名脱敏
 */
function nameHide(name) {
    if (name.length <= 1) return name;
    const firstChar = name.substring(0, 1);
    const maskedPart = '*'.repeat(name.length - 1);
    return firstChar + maskedPart;
}

/**
 * 银行卡号脱敏
 */
function desensitizeCardNumber(cardNumber) {
    if (cardNumber.length <= 4) return cardNumber;
    const firstFour = cardNumber.substring(0, 4);
    const lastFour = cardNumber.substring(cardNumber.length - 4);
    const middle = '*'.repeat(cardNumber.length - 8);
    return firstFour + middle + lastFour;
}

/**
 * 密码脱敏
 */
function passwordMask(passwordInput) {
    setTimeout(() => {
        passwordInput.type = 'password';
    }, 500); // 0.5秒后转换为密码类型
}

export default {
    phoneHide,
    emailHide,
    nameHide,
    cardHide,
    desensitizeCardNumber,
    passwordMask
}