import stateData from "./state";
import service from "../../api";
import desensitize from '@/utils/desensitize'

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setStarlight(state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    getStarlight({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setStarlight", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'STARLIGHT')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setStarlight", {
            provider,
            result_detail: data['STARLIGHT'].data,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    data: state => {
      let result = {
        reportNo: null, base_info: {}, cphs: {}, ident_info_dict: {}, focus_list_check: {}, risk_list_check: {},
        register_history: {}, personal_loan_demand: {}, personal_loan_s: {}, personal_loan_f: {}, personal_loan_h: {}, personal_overdue_history: {},
        rent_history: {}, relevance_risk_check: {}, court_risk_info_list: []
      }
      let data = state.result_detail
      result = { ...result, ...data }
      // 脱敏
      if (result.base_info.name) {
        result.base_info.name = desensitize.nameHide(result.base_info.name)
      }
      if (result.base_info.phone) {
        result.base_info.phone = desensitize.phoneHide(result.base_info.phone)
      }
      if (result.base_info.ident_number) {
        result.base_info.ident_number = desensitize.cardHide(result.base_info.ident_number)
      }

      //  报告编号
      let url = result.url
      if (url) {
        result.reportNo = url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("."))
      }
      // 风险标注
      result.cphs.hit_risk_tagging = (result.cphs.hit_risk_tagging ? result.cphs.hit_risk_tagging : []).map((it, index) => {
        return { key: index + 1, label: it }
      })
      // 身份信息验核
      const idTags = [{ key: '01', text: '不一致' }, { key: '02', text: '一致' }, { key: '00', text: '-' }]
      result.ident_info_dict.identity_two_elements = (idTags.find(it => it.key == result.ident_info_dict.identity_two_elements) || idTags[idTags.length - 1]).text
      // 运营商信息
      // 运营商校验
      const networkTags = [{ key: '01', text: '不一致' }, { key: '02', text: '一致' }, { key: '00', text: '手机号异常/虚拟号/携号转网' }]
      // 在网时长 运营商在网时长 1:[0,3) 2:[3,6) 3:[6,12) 4:[12,24) 5:[24,+) 0：查无此号或已注销 -1：不支持该运营商 -999: 手机状态异常（注:1、销号6月以上；2、携号转网；3、未放出去的号码）；非本网手机号码
      const onlineTags = [{ key: '1', text: '[0,3)' }, { key: '2', text: '[3,6)' }, { key: '3', text: '[6,12)' }, { key: '4', text: '[12,24)' }, { key: '5', text: '[24,+)' }, { key: '0', text: '查无此号或已注销' }, { key: '-1', text: '不支持该运营商' }, { key: '-999', text: '手机状态异常（注:1、销号6月以上；2、携号转网；3、未放出去的号码）；非本网手机号码' }]
      // 所属运营商 运营商类型：移动CMCC，联通CUCC，电信CTCC
      const operatorTags = [{ key: 'CMCC', text: '移动' }, { key: 'CUCC', text: '联通' }, { key: 'CTCC', text: '电信' }, { key: '00', text: '-' }]
      result.ident_info_dict.operatorList = [{
        network_triple_elements: (networkTags.find(it => it.key == result.ident_info_dict.network_triple_elements) || networkTags[networkTags.length - 1]).text,
        time_online: (onlineTags.find(it => it.key == result.ident_info_dict.time_online) || onlineTags[onlineTags.length - 1]).text,
        phone_status: result.ident_info_dict.phone_status,
        operator_type: (operatorTags.find(it => it.key == result.ident_info_dict.operator_type) || operatorTags[operatorTags.length - 1]).text
      }]
      // 不良行为检测  hit1 其他  hit2 是否前科 hit34 是否涉毒 hit5 是否在逃 hit6 是否涉案
      result.focus_list_check.list = [{}]
      let hitArr = [
        { key: 'hit1', label: '其他', value: '否', color: '' },
        { key: 'hit2', label: '是否前科', value: '否', color: '' },
        { key: 'hit34', label: '是否涉毒', value: '否', color: '' },
        { key: 'hit5', label: '是否在逃', value: '否', color: '' },
        { key: 'hit6', label: '是否涉案', value: '否', color: '' },
      ]
      hitArr.map(it => {
        if (result.focus_list_check[it.key] == 1) {
          it.value = '是'
          it.color = 'red'
        }
        result.focus_list_check.list[0][it.key] = it
      })
      // 风险名单检测  归属地位于高风险集中地区 车辆租赁违约名单 犯罪通缉名单 故意违章乘车名单 通信小号库 虚假号码库  特殊关注名单 信用租赁风险名单
      result.risk_list_check.list = [{}]
      let riskArr = [
        { key: 'census_register_high_risk_area', label: '归属地位于高风险集中地区', value: '否', color: '' },
        { key: 'idcard_hit_clzlwy', label: '车辆租赁违约名单', value: '否', color: '' },
        { key: 'idcard_hit_fztj', label: '犯罪通缉名单', value: '否', color: '' },
        { key: 'idcard_hit_gywzcc', label: '故意违章乘车名单', value: '否', color: '' },
        { key: 'phone_hit_txxh', label: '通信小号库', value: '否', color: '' },
        { key: 'phone_hit_xjhm', label: '虚假号码库', value: '否', color: '' },
        { key: 'result_xd', label: '特殊关注名单', value: '否', color: '' },
        { key: 'credit_rent', label: '信用租赁风险名单', value: '否', color: '' }
      ]
      riskArr.map(it => {
        if (result.risk_list_check[it.key] == 1) {
          it.value = '是'
          it.color = 'red'
        }
        result.risk_list_check.list[0][it.key] = it
      })
      // 网贷行为评估
      // 注册记录
      let registerArr = [
        { key: 'D432', label: '消金类机构注册次数', value: '' },
        { key: 'D436', label: '小贷类机构注册次数', value: '' },
        { key: 'D428', label: '银行类机构注册次数', value: '' },
        { key: 'D160', label: '最近次信贷注册月份', value: '' }
      ]
      registerArr.map(it => {
        if (result.register_history[it.key]) {
          it.value = result.register_history[it.key]
        }
      })
      result.register_history.infoList = registerArr

      result.register_history.countList = [{}]
      let registerCountArr = [
        { key: 'registerCountArr', label: '', value: '机构注册次数' },
        { key: 'D384', label: '近1个月网贷机构注册次数', value: '' },
        { key: 'D159', label: '近3个月网贷机构注册次数', value: '' },
        { key: 'D158', label: '近6个月网贷机构注册次数', value: '' },
        { key: 'D157', label: '近12个月网贷机构注册次数', value: '' },
        { key: 'D156', label: '近24个月网贷机构注册次数', value: '' }
      ]
      registerCountArr.map(it => {
        if (result.register_history[it.key]) {
          it.value = result.register_history[it.key]
        }
        result.register_history.countList[0][it.key] = it
      })

      // 查询记录
      result.personal_loan_demand.headers = [
        { key: 'label', label: '' },
        { key: 'd7', label: '近7天' },
        { key: 'm1', label: '近30天' },
        { key: 'm3', label: '近90天' }
      ]
      result.personal_loan_demand.list = [
        { label: '机构查询总数', d7: result.personal_loan_demand.d7_apply_setup_time, m1: result.personal_loan_demand.m1_apply_setup_time, m3: result.personal_loan_demand.m3_apply_setup_time },
        { label: 'P2P平台查询次数', d7: result.personal_loan_demand.d7_apply_setup_time_p2pwd, m1: result.personal_loan_demand.m1_apply_setup_time_p2pwd, m3: result.personal_loan_demand.m3_apply_setup_time_p2pwd },
        { label: '信用卡查询次数', d7: result.personal_loan_demand.d7_apply_setup_time_xyk, m1: result.personal_loan_demand.m1_apply_setup_time_xyk, m3: result.personal_loan_demand.m3_apply_setup_time_xyk },
        { label: '一般消费分期查询次数', d7: result.personal_loan_demand.d7_apply_setup_time_ybxffq, m1: result.personal_loan_demand.m1_apply_setup_time_ybxffq, m3: result.personal_loan_demand.m3_apply_setup_time_ybxffq },
        { label: '大型消费金融查询次数', d7: result.personal_loan_demand.d7_apply_setup_time_dxxfjr, m1: result.personal_loan_demand.m1_apply_setup_time_dxxfjr, m3: result.personal_loan_demand.m3_apply_setup_time_dxxfjr },
        { label: '小额贷款查询次数', d7: result.personal_loan_demand.m3_apply_setup_time, m1: result.personal_loan_demand.m3_apply_setup_time, m3: result.personal_loan_demand.m3_apply_setup_time },
        { label: '其他类型查询次数', d7: result.personal_loan_demand.d7_apply_setup_time_other, m1: result.personal_loan_demand.m1_apply_setup_time_other, m3: result.personal_loan_demand.m3_apply_setup_time_other }
      ]

      // 申请记录
      result.personal_loan_s.infoList = [
        // { label: '近12个月申请机构总数',value:result.personal_loan_s.apply_mechanism_number},
        { label: '消费分期类申请机构数', value: result.personal_loan_s.consumer_apply_mechanism_number },
        { label: '网络贷款类申请机构数', value: result.personal_loan_s.network_loan_apply_mechanis_mnumber },
        { label: '最近一次申请日期', value: result.personal_loan_s.last_apply_time },
        { label: '距离最近一次申请日期已有(天)', value: result.personal_loan_s.last_apply_time_distance }
      ]

      result.personal_loan_s.countList = [
        {
          m0: { key: 'm0', label: '', value: '申请次数' },
          m1: { key: 'm1', label: '近1个月', value: result.personal_loan_s.apply_time1 },
          m2: { key: 'm2', label: '近3个月', value: result.personal_loan_s.apply_time3 },
          m3: { key: 'm3', label: '近6个月', value: result.personal_loan_s.apply_time6 },
          m4: { key: 'm4', label: '近12个月', value: result.personal_loan_s.apply_time12 }
        }
      ]
      // 放款记录
      result.personal_loan_f.infoList = [
        // { label: '放款机构总数',value:result.personal_loan_f.lenders},
        { label: '消费分期类放款机构数', value: result.personal_loan_f.cflenders },
        { label: '网络贷款类放款机构数', value: result.personal_loan_f.nllenders },
        { label: '最近一次放款日期', value: result.personal_loan_f.lend_time },
        { label: '距离最近一次放款日期已有(天)', value: result.personal_loan_f.lend_time_distance }
      ]

      result.personal_loan_f.headers = [
        { key: 'm0', label: '' },
        { key: 'm1', label: '近1个月' },
        { key: 'm2', label: '近3个月' },
        { key: 'm3', label: '近6个月' },
        { key: 'm4', label: '近12个月' },
        { key: 'm5', label: '近24个月' }
      ]
      result.personal_loan_f.list = [
        { m0: '放款次数', m1: result.personal_loan_f.lend_number1, m2: result.personal_loan_f.lend_number3, m3: result.personal_loan_f.lend_number6, m4: result.personal_loan_f.lend_number12, m5: result.personal_loan_f.lend_number24 },
        { m0: '放款金额', m1: result.personal_loan_f.lend_money1, m2: result.personal_loan_f.lend_money3, m3: result.personal_loan_f.lend_money6, m4: result.personal_loan_f.lend_money12, m5: result.personal_loan_f.lend_money24 }
      ]


      // 还款记录
      result.personal_loan_h.headers = [
        { key: 'm0', label: '' },
        { key: 'm1', label: '近1个月' },
        { key: 'm2', label: '近3个月' },
        { key: 'm3', label: '近6个月' },
        { key: 'm4', label: '近12个月' },
        { key: 'm5', label: '近24个月' }
      ]
      result.personal_loan_h.list = [
        { m0: '履约次数', m1: result.personal_loan_h.repay_succ1, m2: result.personal_loan_h.repay_succ3, m3: result.personal_loan_h.repay_succ6, m4: result.personal_loan_h.repay_succ12, m5: result.personal_loan_h.repay_succ24 },
        { m0: '履约金额', m1: result.personal_loan_h.repay_money1, m2: result.personal_loan_h.repay_money3, m3: result.personal_loan_h.repay_money6, m4: result.personal_loan_h.repay_money12, m5: result.personal_loan_h.repay_money24 },
        { m0: '还款异常次数', m1: result.personal_loan_h.repay_fail1, m2: result.personal_loan_h.repay_fail3, m3: result.personal_loan_h.repay_fail6, m4: result.personal_loan_h.repay_fail12, m5: result.personal_loan_h.repay_fail24 }
      ]

      // 历史逾期记录
      let amonutObj = ['0', '(0-500)', '[500-1000)', '[1000-2000)', '[2000-3000)', '[3000-5000)', '[5000-10000)', '[10000-20000)', '[20000-30000)', '[30000-50000)',
        '[50000-100000)', '[100000-200000)', '[200000-300000)', '[300000-500000)', '[500000-1000000)', '[1000000-∞)'];
      result.personal_overdue_history.infoList = [
        { label: '近6个月逾期机构总数', value: result.personal_overdue_history.overdue_mechanism_number },
        { label: '近6个月逾期总次数', value: result.personal_overdue_history.counts },
        { label: '近6个月逾期未结清次数', value: result.personal_overdue_history.uncleared_counts },
        { label: '近6个月逾期总金额(元)', value: amonutObj[result.personal_overdue_history.overdue_money] }
      ]

      result.personal_overdue_history.headers = [{ key: 'index', label: '序号' }, { key: 'amount', label: '逾期金额区间' }, { key: 'date', label: '逾期时间' }, { key: 'count', label: '逾期时长' }, { key: 'settlement', label: '是否结清' }]
      if (result.personal_overdue_history.datalist && result.personal_overdue_history.datalist.length > 0) {
        result.personal_overdue_history.datalist.map((it, index) => {
          it.index = index + 1
          // 是否结清，N否，Y是
          it.settlement = it.settlement == 'Y' ? '是' : '否'
        })
      }

      // 租赁申请行为
      result.rent_history.headers = [
        { key: 'm0', label: '' },
        { key: 'm1', label: '近7天' },
        { key: 'm2', label: '近1个月' },
        { key: 'm3', label: '近3个月' },
        { key: 'm4', label: '近6个月' },
        { key: 'm5', label: '近12个月' }
      ]
      result.rent_history.list = [
        { m0: '租赁申请机构数', m1: result.rent_history.d7_apply_agency_time, m2: result.rent_history.m1_apply_agency_time, m3: result.rent_history.m3_apply_agency_time, m4: result.rent_history.m6_apply_agency_time, m5: result.rent_history.m12_apply_agency_time },
        { m0: '租赁申请次数', m1: result.rent_history.d7_apply_time, m2: result.rent_history.m1_apply_time, m3: result.rent_history.m3_apply_time, m4: result.rent_history.m6_apply_time, m5: result.rent_history.m12_apply_time },
        { m0: '夜间租赁申请机构数', m1: result.rent_history.d7_night_apply_agency_time, m2: result.rent_history.m1_night_apply_agency_time, m3: result.rent_history.m3_night_apply_agency_time, m4: result.rent_history.m6_night_apply_agency_time, m5: result.rent_history.m12_night_apply_agency_time },
        { m0: '夜间租赁申请次数', m1: result.rent_history.d7_night_apply_time, m2: result.rent_history.m1_night_apply_time, m3: result.rent_history.m3_night_apply_time, m4: result.rent_history.m6_night_apply_time, m5: result.rent_history.m12_night_apply_time }
      ]

      result.rent_history.linkList = [{
        m0: { label: '同一身份证关联手机号数', value: result.rent_history.ident_contain_phone_ct },
        m1: { label: '同一手机号关联身份证数', value: result.rent_history.phone_contain_ident_ct },
        m2: { label: '最近一次申请时间', value: result.rent_history.ident_last_apply_time }
      }]

      // 关联风险检测
      result.relevance_risk_check.list = [{
        m0: { label: '3个月身份证关联手机号数', value: result.relevance_risk_check.m3_idcard_to_phone_time },
        m1: { label: '3个月手机号关联身份证数', value: result.relevance_risk_check.m3_phone_to_idcard_time }
      }]

      // 法院风险信息
      result.court_risk_info_list_headers = [
        { key: 'index', label: '序号' },
        { key: 'sort_time_string', label: '审结日期' },
        { key: 'data_type', label: '类型' },
        { key: 'summary', label: '摘要说明' },
        { key: 'compatibility', label: '匹配度' }
      ]

      let typeObj = {
        "cpws": "裁判文书",
        "ktgg": "开庭公告",
        "ajlc": "案件流程信息",
        "fygg": "法院公告",
        "shixin": "失信公告",
        "zxgg": "执行公告",
        "bgt": "曝光台"
      }
      result.court_risk_info_list.map((it, index) => {
        it.data_type = typeObj[it.data_type]
        it.index = index + 1
      })
      return result
    }
  }
};
